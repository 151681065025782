<template>
  <v-footer id="home-footer" color="black" dark min-height="72">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <div
            class="d-flex flex-wrap justify-md-start justify-center justify-md-none"
          >
            <template v-for="(s, i) in social">
              <!--
              <v-icon
                :key="s.name + i"
                class="white--text"
              >
                {{ s.icon }}
              </v-icon>
              <a
                :key="s.name"
                class="white--text pa-1 pa-md-0"
                :href="s.link"
                target="_blank"
                v-text="s.name"
              />
              -->
              <v-btn
                :key="s.name"
                :href="s.link"
                target="_blank"
                color="transparent"
              >
                <v-icon left>
                  {{ s.icon }}
                </v-icon>
                {{ s.name }}
              </v-btn>
              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${s.name}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>

        <v-col class="text-center text-md-right" cols="12" md="4">
          &copy; 2023 KIDIWI Digital
        </v-col>
        <v-col cols="12" md="2">
          <!--
            Facing issue https://github.com/vuetifyjs/vuetify/issues/11121
            where nav bar overlaps footer - hence moving stuff a bit
           -->
          &nbsp;
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",

  data: () => ({
    social: [
      {
        name: "Facebook",
        link: "https://www.facebook.com/kidiwiservices",
        icon: "mdi-facebook",
      },
      {
        name: "YouTube",
        link: "https://www.youtube.com/@kidiwiservices",
        icon: "mdi-youtube",
      },
    ],
  }),
};
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>
